body{
  background: black;
  color: white;
  margin: 0;
}

*{
  box-sizing: border-box;
}
a{
  color: black;
}
.header, .footer{
  width:100%;
  padding: 20px;
  background: white;
  color: black
}
.header ul li{
  display: inline-block;
  padding: 10px;
}

.container{
  width: 80%;
  max-width: 1200px;
  padding: 30px;
}